import Container, { Modal } from './styles';

import logo from 'assets/img/logo.png';
import MenuIcon from 'assets/img/menuIcon.svg';
import MenuOpenIcon from 'assets/img/menuOpenIcon.svg';

import Button from 'components/Button';
import { useEffect, useState } from 'react';

const Header = () => {
  const [scroll, setScroll] = useState(false);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        setScroll(true);
        return;
      }
      setScroll(false);
    });

    return window.removeEventListener('scroll', () => {
      if (window.scrollY > 0) {
        setScroll(true);
        return;
      }
      setScroll(false);
    });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth > 700) {
        setOpen(false);
      }
    });

    return window.removeEventListener('resize', () => {
      if (window.innerWidth > 700) {
        setOpen(false);
      }
    });
  }, []);
  return (
    <>
      <Modal show={open} onClick={() => setOpen(false)} />
      <Container open={open} scroll={scroll}>
        <div>
          <div className="image">
            <img src={logo} alt="logo" />
            <button
              type="button"
              className="open"
              onClick={() => setOpen(true)}
            >
              <img src={MenuIcon} alt="abrir" />
            </button>
            <button
              type="button"
              className="close"
              onClick={() => setOpen(false)}
            >
              <img src={MenuOpenIcon} alt="fechar" />
            </button>
          </div>
          <nav>
            <ul>
              <li>
                <a href="#sobre" onClick={() => setOpen(false)}>
                  Solução
                </a>
              </li>
              <li>
                <a href="#soluções" onClick={() => setOpen(false)}>
                  Contrate
                </a>
              </li>
              <li>
                <div className="button">
                  <a href="#contato" onClick={() => setOpen(false)}>
                    <Button>Fale Conosco</Button>
                  </a>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </Container>
    </>
  );
};

export default Header;

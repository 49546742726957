import Container, { CarrouselContainer, Content, ImageSlide } from './styles';

import Button from 'components/Button';
import Carrousel from 'nuka-carousel';
import { FC, useEffect, useRef, useState, useContext } from 'react';
import { MdClose } from 'react-icons/md';
import Context from 'services/Context';
import { ModuleRequest } from 'types/modules';

interface CardDescriptionProps extends ModuleRequest {
  addModule: () => void;
  closeModal?: () => void;
}

const CardDescription: FC<CardDescriptionProps> = ({
  addModule,
  id,
  description,
  title,
  disabled,
  images,
  closeModal = () => {},
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { cart } = useContext(Context);
  const [height, setHeight] = useState<number>();
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    if (window.innerWidth <= 500) {
      setHeight(undefined);
      return;
    }
    setHeight(ref?.current?.clientHeight);
  }, [ref, id]);
  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 500) {
        setHeight(undefined);
        return;
      }
      setHeight(ref?.current?.clientHeight);
    });
  }, []);
  useEffect(() => {
    const existProduct = cart.products.find(product => product.id === id);
    if (existProduct) {
      setSelected(true);
      return;
    }
    setSelected(false);
  }, [cart.products, id]);

  return (
    <Container ref={ref}>
      <button onClick={closeModal} className="button_close" type="button">
        <MdClose size={16} color="#C2DEFF" />
      </button>
      <CarrouselContainer>
        <Carrousel
          slidesToShow={1}
          vertical={!!height}
          defaultControlsConfig={{
            nextButtonStyle: { display: 'none' },
            prevButtonStyle: { display: 'none' },
            pagingDotsClassName: 'pading_name',
            pagingDotsContainerClassName: 'pading_name_container',
          }}
        >
          {images.map((image, index) => (
            <ImageSlide
              imageHeight={height}
              className="image"
              key={index}
              src={image.source}
              alt={`${title}-${index}`}
            />
          ))}
        </Carrousel>
      </CarrouselContainer>
      <Content>
        <h1>{title}</h1>
        <div className="text">
          <p>{description}</p>
        </div>
        <div className="buttons">
          <Button
            disabled={!disabled}
            onClick={() => addModule()}
            type="button"
            selected={selected}
          >
            {`${selected ? 'Módulo Adicionado' : ' Adicionar módulo'}`}
          </Button>
          <a
            href="#contato"
            onClick={closeModal}
            className="secondary"
            type="button"
          >
            Dúvidas? Fale conosco
          </a>
        </div>
      </Content>
    </Container>
  );
};

CardDescription.defaultProps = {
  closeModal: () => {},
};

export default CardDescription;

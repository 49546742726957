import styled from 'styled-components';

export default styled.div<{ show: boolean; percent: number | undefined }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  z-index: 5;

  > button {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(10, 33, 85, 0.25);
    backdrop-filter: blur(35px);
    border: none;
  }

  > div {
    z-index: 2;
    width: 62%;
    height: 62%;
    background-color: #fff;
    border-radius: 1.5rem;

    min-height: 500px;
  }

  @media (max-width: 1400px) {
    > div {
      width: 80%;
      height: 80%;
    }
  }

  @media (max-width: 500px) {
    border-radius: 1.5rem 1.5rem 0 0;
    > div {
      border-radius: 1.5rem 1.5rem 0 0;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: ${({ percent }) => `${percent}%`};
      min-height: ${({ percent }) => `${percent}%`};
    }
  }
`;

import { createContext, FC, useState } from 'react';
import { ModuleRequest } from 'types/modules';

interface productType {
  id: string;
  title: string;
  description: string;
  images: Array<{
    url: string;
  }>;
  price: number | null;
  active: boolean;
}

interface productTypeFinished extends ModuleRequest {
  priceFormat: string;
}

interface ContextProps {
  cart: {
    products: Array<productTypeFinished>;
    total: number;
    totalFormat: string;
    totalProducts: number;
  };
  addProduct: (product: ModuleRequest) => void;
  removeProduct: (id: string) => void;
}

const Context = createContext({} as ContextProps);

export const AppContextProvider: FC = ({ children }) => {
  const [cart, setCart] = useState<{
    products: Array<productTypeFinished>;
    total: number;
    totalFormat: string;
    totalProducts: number;
  }>({
    products: [
      {
        id: '0',
        title: 'SLIDE DE APRESENTAÇÃO',
        description:
          'Com imagem da clínica podendo redirecionar para link, até 5 imagens',
        price: -1,
        priceFormat: 'Incluso',
        version: 1.0,
        images: [
          {
            id: '0',
            mimetype: '',
            source:
              'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2F2.bp.blogspot.com%2F_FnjjYyyLWYQ%2FTCKrpcbIsxI%2FAAAAAAAADIc%2F9D9wUndUqPw%2Fs1600%2FEsposende.jpg&f=1&nofb=1',
          },
          {
            id: '0',
            mimetype: '',
            source:
              'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2F2.bp.blogspot.com%2F_FnjjYyyLWYQ%2FTCKrpcbIsxI%2FAAAAAAAADIc%2F9D9wUndUqPw%2Fs1600%2FEsposende.jpg&f=1&nofb=1',
          },
          {
            id: '0',
            mimetype: '',
            source:
              'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2F2.bp.blogspot.com%2F_FnjjYyyLWYQ%2FTCKrpcbIsxI%2FAAAAAAAADIc%2F9D9wUndUqPw%2Fs1600%2FEsposende.jpg&f=1&nofb=1',
          },
        ],
        disabled: true,
      },
      {
        id: '1',
        title: 'SOBRE A CLÍNICA',
        description: 'Podendo escrever um texto sobre a clínica',
        price: -1,
        priceFormat: 'Incluso',
        version: 1.0,
        images: [
          {
            id: '0',
            mimetype: '',
            source:
              'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2F2.bp.blogspot.com%2F_FnjjYyyLWYQ%2FTCKrpcbIsxI%2FAAAAAAAADIc%2F9D9wUndUqPw%2Fs1600%2FEsposende.jpg&f=1&nofb=1',
          },
          {
            id: '0',
            mimetype: '',
            source:
              'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2F2.bp.blogspot.com%2F_FnjjYyyLWYQ%2FTCKrpcbIsxI%2FAAAAAAAADIc%2F9D9wUndUqPw%2Fs1600%2FEsposende.jpg&f=1&nofb=1',
          },
          {
            id: '0',
            mimetype: '',
            source:
              'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2F2.bp.blogspot.com%2F_FnjjYyyLWYQ%2FTCKrpcbIsxI%2FAAAAAAAADIc%2F9D9wUndUqPw%2Fs1600%2FEsposende.jpg&f=1&nofb=1',
          },
        ],
        disabled: true,
      },
      {
        id: '2',
        title: 'DADOS DE CONTATO',
        description:
          'podendo colocar alguns dados predefinidos para contato no rodapé:  Telefone (callcenter), WhatsApp, Endereço completo, email',
        price: -1,
        priceFormat: 'Incluso',
        version: 1.0,
        images: [
          {
            id: '0',
            mimetype: '3',
            source:
              'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2F2.bp.blogspot.com%2F_FnjjYyyLWYQ%2FTCKrpcbIsxI%2FAAAAAAAADIc%2F9D9wUndUqPw%2Fs1600%2FEsposende.jpg&f=1&nofb=1',
          },
          {
            id: '0',
            mimetype: '4',
            source:
              'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2F2.bp.blogspot.com%2F_FnjjYyyLWYQ%2FTCKrpcbIsxI%2FAAAAAAAADIc%2F9D9wUndUqPw%2Fs1600%2FEsposende.jpg&f=1&nofb=1',
          },
          {
            id: '0',
            mimetype: '4',
            source:
              'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2F2.bp.blogspot.com%2F_FnjjYyyLWYQ%2FTCKrpcbIsxI%2FAAAAAAAADIc%2F9D9wUndUqPw%2Fs1600%2FEsposende.jpg&f=1&nofb=1',
          },
        ],
        disabled: true,
      },
    ],
    total: 19.9,
    totalFormat: '19,90',
    totalProducts: 3,
  });

  function removeProduct(id: string) {
    let newTotal = cart.total;
    const newProducts = cart.products.filter(product => {
      if (product.price === null) {
        return true;
      }
      if (product.id === id) {
        newTotal -= product.price || 0;
      }

      return product.id !== id;
    });
    const newTotalFormat = `${newTotal
      .toFixed(2)
      .toString()
      .replace('.', ',')}`;
    setCart(props => ({
      products: newProducts,
      total: newTotal,
      totalFormat: newTotalFormat,
      totalProducts: newProducts.length,
    }));
  }

  function addProduct(newProduct: ModuleRequest) {
    const existProduct = cart.products.find(
      product => product.id === newProduct.id
    );
    if (existProduct) {
      return;
    }
    const priceFormat = `R$ ${(newProduct.price || 0)
      .toFixed(2)
      .toString()
      .replace('.', ',')}`;

    const newTotal = cart.total + (newProduct.price || 0);
    const newTotalFormat = `${newTotal
      .toFixed(2)
      .toString()
      .replace('.', ',')}`;

    const newProducts = [...cart.products, { ...newProduct, priceFormat }];

    setCart(props => ({
      products: newProducts,
      total: newTotal,
      totalFormat: newTotalFormat,
      totalProducts: newProducts.length,
    }));
  }
  return (
    <Context.Provider value={{ cart, addProduct, removeProduct }}>
      {children}
    </Context.Provider>
  );
};

export default Context;

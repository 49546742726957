import styled, { css, keyframes } from 'styled-components';

const animateMouse = keyframes`

  0% {

    top:80%
  }
  50%{

    top:75%;
  }

  to {
    top:80%
  }

`;
const animateMouseSmall = keyframes`

  0% {

    top:110%
  }
  50%{

    top:105%;
  }

  to {
    top:110%
  }

`;

export default styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5.43rem;
  flex: 1;
  position: relative;
  padding: 0 5rem;

  .plant {
    position: absolute;
    bottom: 0;
    right: -4.5rem;
  }

  .content {
    position: relative;
    display: flex;
    flex: 1;
    max-width: 86rem;
    padding: 0 5rem;

    > div {
      display: flex;
      flex-direction: column;
    }

    h1,
    strong {
      max-width: 41.87rem;
      font-weight: 600;
      font-size: 3.12rem;
      align-self: flex-start;
      color: ${({ theme }) => theme.colors.primary};
    }
    strong {
      color: ${({ theme }) => theme.colors.secondary};

      &.texts {
        position: relative;
        ::after {
          display: block;
          content: '';
          position: absolute;
          right: -0.5rem;
          top: 0;
          transform: translateY(20%);
          height: 80%;
          border-right: 2px solid ${({ theme }) => theme.colors.secondary};
        }
      }

      &.finished {
        background: rgba(4, 112, 244, 0.18);

        ::after {
          display: none;
        }
      }
    }
    img {
      margin-top: 6rem;
      align-self: flex-end;
      max-width: 57.3rem;
      object-fit: contain;
      z-index: 2;
    }

    span {
      position: absolute;
      bottom: -90px;
      left: 0;
      width: 100%;
      height: 20rem;
      z-index: -1;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 33.85%
      );
    }

    .mouse {
      width: auto;
      z-index: 2;
      position: absolute;
      left: 14%;
      animation: ${animateMouse} 5s infinite;
      height: fit-content;
      background: none;

      > img {
        margin: 0;
      }
    }

    .city {
      width: auto;
      z-index: -2;
      position: absolute;
      bottom: -3.12rem;
      right: 24.37rem;
      max-width: 50rem;
    }
  }

  @media (max-width: 900px) {
    margin-top: 2rem;
    padding: 0 5rem;
    .content {
      flex-direction: column;
      margin-bottom: 10rem;

      .mouse {
        left: 50%;
        animation: ${animateMouseSmall} 5s infinite;
      }
    }

    img {
      width: 100%;
      max-width: 100%;
    }

    .plant {
      width: 80% !important;
      right: 0;
    }
    h1 {
      font-size: 2.5rem;
    }

    .city {
      height: 31rem;
      right: 0;
      bottom: 0;
    }
  }
  @media (max-width: 700px) {
    padding: 0 2rem;
    .content {
      padding: 0;
    }
  }
  @media (max-width: 400px) {
    padding: 0 1.3rem;

    .city {
      right: 0;
      bottom: 0;
    }
  }
`;
export const TextsTyping = styled.strong<{ finished: boolean }>`
  color: ${({ theme }) => theme.colors.secondary};
  position: relative;
  min-height: 4.75rem;
  height: 4.75rem;
  ::after {
    display: ${({ finished }) => (finished ? 'none' : 'block')};
    content: '';
    position: absolute;
    right: -0.5rem;
    top: 0;
    transform: translateY(20%);
    height: 80%;
    border-right: 2px solid ${({ theme }) => theme.colors.secondary};
  }

  ${({ finished }) =>
    finished &&
    css`
      background: rgba(4, 112, 244, 0.18);
    `}
`;

import Module from 'Layouts/Module';
import styled, { css } from 'styled-components';

export default styled(Module)`
  padding-bottom: 5.62rem;
  background: linear-gradient(
    360deg,
    #e3f3ff 0%,
    rgba(239, 248, 255, 0.28) 22.4%,
    #ffffff 69.79%
  );
  .content {
    display: flex;
    max-width: 86rem;
    padding: 0 5rem;
    margin: 0 auto;
    align-self: stretch;
    justify-content: space-between;
    width: 100%;

    > div {
      max-width: 30.56rem;
      display: flex;
      flex-direction: column;
      p,
      strong {
        font-family: ${({ theme }) => theme.fontFamily.secondary};
        font-size: 1.31rem;
        color: #949cb5;
        font-weight: 400;
      }
      strong {
        font-weight: 500;
      }
      > p {
        margin-bottom: 1.87rem;
      }

      a {
        display: flex;
        border: 2px solid #c2deff;
        border-radius: 1.56rem;
        padding: 1rem 1.56rem;
        text-decoration: none;
        align-items: center;
        min-width: 16.48rem;
        align-self: flex-start;
        margin-bottom: 1rem;

        p {
          margin-left: 1rem;
        }
      }
    }
  }

  @media (max-width: 900px) {
    .content {
      flex-direction: column;
    }
  }

  @media (max-width: 700px) {
    .content {
      padding: 0;
    }
  }
  @media (max-width: 300px) {
    .content > div p,
    strong {
      font-size: 1rem;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.87rem;
  min-width: 34.6rem;
  max-width: 34.6rem;

  .inputContainer {
    display: flex;
    gap: 1.37rem;

    input {
      :first-child {
        flex: 1;
      }
      :last-child {
        flex: 2;
      }
    }
  }

  .buttons {
    gap: 5.87rem;
    display: flex;

    .button {
      flex: 1;
    }
  }

  @media (max-width: 900px) {
    padding-top: 2rem;
    margin-top: 2rem;

    min-width: 0;
    max-width: 100%;
    border-top: 1px solid #c2deff;
    .inputContainer {
      flex-direction: column;
    }

    .buttons {
      gap: 2.87rem;
      flex-direction: column;
    }
  }
`;

export const TexArea = styled.textarea<{ error: boolean }>`
  border: 2px solid #c2deff;
  box-sizing: border-box;
  border-radius: 1.56rem;
  font-size: 1.31rem;
  height: 13.75rem;
  padding-left: 1.25rem;
  padding-top: 1.25rem;
  resize: none;
  font-weight: 400;
  outline: none;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  color: ${({ theme }) => theme.colors.primary};

  ${({ error }) =>
    error &&
    css`
      border-color: #e77878;

      input {
        color: #e77878;
      }
    `}

  ::placeholder {
    color: #949cb5;
    font-weight: 400;
  }
`;

export const Alert = styled.p<{ type?: string }>`
  flex: 1;
  max-width: 14.43rem;
  font-size: 1.125rem;
  ${({ type, theme }) => {
    switch (type) {
      case 'success':
        return css`
          color: ${theme.colors.secondary};
        `;
      case 'error':
        return css`
          color: red;
        `;
      default:
        return css`
          opacity: 0;
        `;
    }
  }}
`;

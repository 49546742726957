import styled from 'styled-components';

export default styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: stretch;
  position: relative;

  .button_close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 1.875rem;
    height: 1.875rem;
    border: 2px solid #c2deff;
    border-radius: 100%;
    background: none;
    cursor: pointer;
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const ImageSlide = styled.img.attrs<{ imageHeight: number | undefined }>(
  ({ imageHeight }) => ({
    style: {
      height: imageHeight ? `${imageHeight}px` : '100%',
    },
  })
)<{ imageHeight: number | undefined }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1.5rem 0 0 1.5rem;

  @media (max-width: 500px) {
    border-radius: 1.5rem 1.5rem 0 0;
  }
`;

export const CarrouselContainer = styled.div`
  background: #ecf7ff;
  border-radius: 1.5rem 0px 0px 1.5rem;
  flex: 1;
  display: flex;
  align-self: stretch;
  .slider-control-bottomcenter {
    bottom: 50% !important;
    right: 0 !important;
    left: auto !important;
    margin-right: 1.2rem;
    transform: translateY(50%) !important;

    .pading_name_container {
      flex-direction: column;
      .pading_name {
        background: ${({ theme }) => theme.colors.primary} !important;
        width: 1rem;
        height: 1rem;
        border-radius: 100%;

        svg {
          display: none !important;
        }
      }
    }
  }
  .slide {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 500px) {
    border-radius: 1.5rem 1.5rem 0 0;
    align-self: stretch;
    flex: 2;

    .slider-control-bottomcenter {
      bottom: 0 !important;
      right: auto !important;
      left: 50% !important;
      margin-right: 1.2rem;
      transform: translateX(-50%) !important;

      .pading_name_container {
        flex-direction: row;
        gap: 1rem;
        .pading_name {
          background: ${({ theme }) => theme.colors.primary} !important;
          width: 1rem;
          height: 1rem;
          border-radius: 100%;

          svg {
            display: none !important;
          }
        }
      }
    }
    .slider-list,
    .slider-slide {
      height: 100% !important;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  padding: 0 4.375rem;
  padding-top: 5.625rem;
  padding-bottom: 1.87rem;
  h1 {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1.875rem;
    font-weight: 600;
  }

  .text {
    flex: 1;
    min-height: 10rem;
    overflow: auto;
    p {
      margin-top: 1.875rem;
      font-family: ${({ theme }) => theme.fontFamily.secondary};
      font-size: 1.31rem;
      color: #949cb5;

      line-height: 135%;
    }
  }

  .buttons {
    align-self: center;
    max-width: 14.69rem;
    margin-top: 3.125rem;

    .secondary {
      display: block;
      color: #949cb5;
      font-size: 1.31rem;
      background: none;
      border: none;
      width: 100%;
      margin-top: 1rem;
      text-decoration: none;
      text-align: center;
      transition: color 0.2s linear, font-weight 0.2s linear;

      :hover {
        color: #0470f4;
        font-weight: 600;
      }
    }
  }

  @media (max-width: 500px) {
    overflow: auto;
    padding: 2rem;

    .buttons {
      margin-top: 1rem;
    }
  }
`;

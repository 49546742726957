import Container from './styles';

import { FC, InputHTMLAttributes } from 'react';
import { FcCheckmark } from 'react-icons/fc';

interface inputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  success?: boolean;
}

const Input: FC<inputProps> = ({
  children,
  success = false,
  error = false,
  className,
  ...props
}) => (
  <Container className={className} error={error}>
    <input {...props} />
    {success && <FcCheckmark />}
  </Container>
);

Input.defaultProps = {
  error: false,
  success: false,
};

export default Input;

import styled, { css } from 'styled-components';

export default styled.div<{ open: boolean; showCart: boolean }>`
  width: 25rem;
  height: 31.25rem;
  position: fixed;
  bottom: 0;
  right: 3.75rem;
  background-color: #fff;
  z-index: 5;
  display: flex;
  flex-direction: column;
  border-radius: 1.5rem 1.5rem 0px 0px;
  transition: transform 0.2s linear;

  ${({ open }) =>
    !open &&
    css`
      transform: translateY(83.8%);
    `}
  ${({ showCart, open }) =>
    !showCart &&
    !open &&
    css`
      transform: translateY(100%);
    `}

  > button {
    cursor: pointer;
    background: linear-gradient(265.62deg, #2db5ff 0%, #0470f4 100%);
    border-radius: 1.5rem 1.5rem 0px 0px;
    height: 16.2%;
    width: 100%;
    display: flex;
    color: #fff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      align-self: stretch;
      display: flex;
      align-items: center;
    }

    p {
      font-size: 1.87rem;
      font-weight: 600;
      margin-right: 1.93rem;
    }
  }

  @media (max-width: 500px) {
    width: 100%;
    right: 0;
    height: 64%;
    ${({ open }) =>
      !open &&
      css`
        transform: translateY(100%);
      `}
    > button {
      background: #fff;

      > div {
        display: none;
      }

      p {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

export const Modal = styled.button<{ show: boolean }>`
  position: fixed;
  z-index: 5;
  background: rgba(10, 33, 85, 0.25);
  backdrop-filter: blur(35px);
  width: 100%;
  height: 100%;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  outline: none;
  @media (min-width: 501px) {
    display: none;
  }
`;
export const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  overflow: auto;

  > a {
    max-width: 12.375rem;
    border: none;
    background: none;
    text-decoration: none;
    align-self: center;
    margin-top: 1rem;
    color: #949cb5;
    font-size: 1.31rem;
  }

  .price {
    padding: 0 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.primary};
    p {
      font-size: 1.31rem;
      font-family: ${({ theme }) => theme.fontFamily.secondary};
      background: linear-gradient(265.62deg, #2db5ff 0%, #0470f4 100%);

      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      :last-child {
        background: none;
        -webkit-text-fill-color: #949cb5;
        color: #949cb5;
      }
    }
    strong {
      font-size: 1.87rem;
    }
  }
`;

export const ModulesContainer = styled.div`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const Module = styled.div`
  border-bottom: 1px solid #e4f3ff;
  padding: 0 0.625rem;
  padding-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    color: #949cb5;
    font-size: 1.31rem;

    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    flex: 1;
    margin-right: 2rem;
  }

  > div {
    display: flex;
    align-items: center;
    p {
      font-weight: 500;
      margin-right: 1.25rem;
    }
    button {
      display: flex;
      place-content: center;
      background: none;
      border: none;
      cursor: pointer;
    }
  }
`;

export const CartContainer = styled.button<{ showCart: boolean }>`
  width: 5rem;
  height: 5rem;
  z-index: 4;
  background: linear-gradient(265.62deg, #2db5ff 0%, #0470f4 100%);
  box-shadow: 0px 15px 32px -8px rgba(4, 112, 244, 0.34);
  border-radius: 100%;
  position: fixed;
  bottom: 1.25rem;
  padding: 1rem;
  right: 1.25rem;
  border: none;

  ${({ showCart }) =>
    !showCart &&
    css`
      display: none;
    `}

  img {
    width: 2.9rem;
    object-fit: contain;
  }

  span {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1.12rem;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 501px) {
    display: none;
  }
`;

import Module from 'Layouts/Module';
import styled from 'styled-components';

export default styled(Module)`
  padding-bottom: 5.62rem;

  h2 {
    margin-bottom: 0;
  }

  .searchInput {
    width: 100%;
    max-width: 19.75rem;
    height: 3.25rem;
    border: 2px solid #c2deff;
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    border-radius: 1.5rem;
    margin-top: 1.87rem;
    margin-bottom: 3.12rem;

    input {
      outline: none;
      flex: 1;
      margin-left: 1rem;
      align-self: stretch;
      border: none;
      color: #949cb5;
      font-size: 1.31rem;
    }
  }

  .content {
    max-width: 86rem;
    display: flex;
    flex: 1;
    padding: 0 5rem;
    align-self: stretch;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;

    .carrousel {
      padding-bottom: 4.37rem;
      margin-bottom: 3.37rem;
      min-height: 35.8rem;

      .cards {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 3.12rem;
      }
      .slider-control-bottomright {
        bottom: 0 !important;
        right: 0 !important;
        width: 100%;
      }
    }

    .buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        border: none;
        cursor: pointer;
      }
      .dots_name {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        button {
          background-color: #c2deff;
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 100%;

          &.active {
            background-color: ${({ theme }) => theme.colors.primary};
          }
        }
      }
    }
  }

  @media (max-width: 1400px) {
    padding-bottom: 5.62rem;
  }

  @media (max-width: 700px) {
    .content {
      padding: 0;
    }
  }
`;

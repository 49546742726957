import styled from 'styled-components';

export default styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: stretch;
  position: relative;

  .modalClose {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(194, 222, 255, 0.4);
      border-radius: 1.5rem;
    }
    > div {
      width: 47%;
      z-index: 3;
      height: 17.31rem;
      background: #fff;
      border-radius: 1.5rem;
      padding: 2.6rem 3.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h2 {
        font-size: 2.25rem;
        color: ${({ theme }) => theme.colors.primary};
        font-weight: 600;
      }

      p {
        color: #949cb5;
        max-width: 25.04rem;
        text-align: center;
        font-size: 1.31rem;
      }

      .buttons {
        margin-top: 1.6rem;
        display: flex;
        gap: 1rem;

        > button {
          cursor: pointer;
          background: none;
          border: 1.5px solid #c2deff;
          border-radius: 0.5rem;
          font-size: 1.32rem;
          color: ${({ theme }) => theme.colors.primary};
          font-weight: 600;
          width: 7.18rem;
        }
      }
    }
  }

  h2 {
    font-size: 1.87rem;
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-weight: 400;
  }

  .button_close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 1.875rem;
    height: 1.875rem;
    border: 2px solid #c2deff;
    border-radius: 100%;
    background: none;
    cursor: pointer;
  }

  @media (max-width: 500px) {
    flex-direction: column;

    .modalClose {
      > div {
        width: 90%;
      }
    }
  }
`;

export const ContentContainer = styled.div`
  padding-top: 3.12rem;
  padding-bottom: 1.87rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;

  > div {
    > span {
      font-weight: 300;
      font-size: 1.5rem;
      color: ${({ theme }) => theme.colors.secondary};
      font-family: ${({ theme }) => theme.fontFamily.secondary};
    }
    flex: 1;
    align-self: stretch;
    max-width: 23.69rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 2.5rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.primary};
    }

    h2 {
      color: #949cb5;
      margin-top: 1rem;
    }

    .inputs {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      align-self: stretch;
      margin-top: 1.87rem;

      .select {
        border: 2px solid #c2deff;
        box-sizing: border-box;
        border-radius: 1.56rem;
        color: ${({ theme }) => theme.colors.primary};
        font-size: 1.31rem;
        width: 100%;
        flex: 1;
        display: flex;
        overflow: hidden;
        padding-right: 1rem;
        align-items: center;
        position: relative;
        min-height: 3.12rem;

        .mask {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .row {
        gap: 1.3rem;
        display: flex;
      }

      .input {
        flex: 1;
      }
      .large {
        flex: 2.75;
      }
    }

    .buttons {
      margin-top: auto;
      display: flex;
      gap: 1rem;
      width: 100%;
      align-self: center;
      max-width: 18.13rem;
      .gray {
        background: #949cb5;
      }
      > div,
      > button {
        flex: 1;
      }
      > button {
        cursor: pointer;
        background: none;
        border: 1.5px solid #c2deff;
        border-radius: 0.5rem;
        font-size: 1.32rem;
        color: ${({ theme }) => theme.colors.primary};
        font-weight: 600;
      }
    }
  }
`;

export const ProductsContainer = styled.div`
  h2 {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600;
    margin-bottom: 1.87rem;
  }
  padding: 0 1.87rem;
  padding-top: 3.125rem;
  padding-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  flex: 1.4;
  box-shadow: 0px 50px 70px -5px #e4f3ff;
  border-radius: 1.5rem 0px 0px 1.5rem;

  .price {
    padding: 0 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.primary};
    p {
      background: linear-gradient(265.62deg, #2db5ff 0%, #0470f4 100%);

      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 1.31rem;
      font-family: ${({ theme }) => theme.fontFamily.secondary};

      :last-child {
        background: none;
        -webkit-text-fill-color: #949cb5;
        color: #949cb5;
      }
    }
    strong {
      font-size: 1.87rem;
    }
  }

  > button {
    background: none;
    align-self: center;
    border: 1.5px solid #c2deff;

    border-radius: 0.5rem;
    min-height: 3rem;
    width: 100%;
    cursor: pointer;
    max-width: 17.3rem;
    font-weight: bold;
    font-size: 1.31rem;
    color: ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: 800px) {
    flex: 0.5;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const ModulesContainer = styled.div`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const Module = styled.div`
  border-bottom: 1px solid #e4f3ff;
  padding: 1.25rem 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > p {
    color: #949cb5;
    font-size: 1.31rem;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
  }

  > div {
    display: flex;
    align-items: center;
    p {
      font-weight: 500;
      color: #949cb5;
      margin-right: 1.25rem;
    }
    button {
      display: flex;
      place-content: center;
      background: none;
      border: none;
      cursor: pointer;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
    > p {
      max-width: 110px;
    }
  }
`;

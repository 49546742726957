import Module from 'Layouts/Module';
import styled from 'styled-components';

export default styled(Module)`
  .content {
    max-width: 86rem;
    display: flex;
    align-self: stretch;
    justify-content: space-between;
    z-index: 1;
    flex: 1;
    padding: 0 5rem;
    margin: 0 auto;

    .text {
      flex: 1;
      margin-left: 1rem;
      max-width: 31.5rem;
      p {
        line-height: 135%;
        color: #949cb5;
        font-weight: 400;
        font-family: ${({ theme }) => theme.fontFamily.secondary};
        strong {
          font-family: ${({ theme }) => theme.fontFamily.secondary};
          font-weight: 500;
        }
        :first-child {
          margin-bottom: 2rem;
        }
      }
    }
  }
  > img {
    width: 100%;
    position: absolute;
    top: -250px;
    z-index: -1;
    left: 0;
    /* clip-path: path(
      'M1920 81.4995C1920 81.4995 1777 -0.000637412 1459.5 -0.000244139C1142 0.000149134 788.001 172 477.001 172C166.001 172 0.000976562 81.4995 0.000976562 81.4995V877H1920L1920 81.4995Z'
    );
    background: linear-gradient(
      180deg,
      #e3f3ff 0%,
      rgba(239, 248, 255, 0.79) 30.3%,
      rgba(241, 249, 255, 0.5) 47.7%,
      #ffffff 86.38%
    ); */
  }

  @media (max-width: 900px) {
    .content {
      flex-direction: column;
      > img {
        width: 100%;
        max-width: 100%;
      }

      .text {
        align-self: center;
        margin-top: 2.5rem;
      }
    }
  }

  @media (max-width: 700px) {
    .content {
      padding: 0;
    }
  }
`;
export const Image = styled.div`
  width: 100%;
  flex: 1.5;
  max-width: 58%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }

  .woman {
    width: 20%;
    object-fit: contain;
    position: absolute;
    top: 0;
    height: auto;
    left: auto !important;
    right: 38.5% !important;
    z-index: 3;
  }

  .blueArrow {
    position: absolute;
    top: 56%;
    right: 0;
    width: 15%;
    height: auto;
  }
  .greenArrow {
    position: absolute;
    left: 0;
    width: 15%;
    height: auto;
    bottom: 0;
  }

  .puzzle {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    width: 60%;
    top: 32%;
    left: 0;
    height: 15%;
    z-index: 1;

    img {
      width: auto;
      height: 100%;
      object-fit: contain;
      :first-child {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
      }
      :last-child {
        z-index: 2;
      }
    }
    :last-child {
      top: 55%;
      left: 5%;
    }
  }

  .puzzle2 {
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex: 1;

    img {
      :first-child {
        z-index: 2;
      }
      :last-child {
        z-index: 1;
      }
    }
  }

  @media (max-width: 900px) {
    max-width: 100%;
  }
`;

import formikValues from '../formikValuesTypes';

import Input from 'components/Input';
import { FormikProps } from 'formik';
import { FC } from 'react';
import maskCardNumber from 'utils/maskCardNumber';
import maskDate from 'utils/maskDate';

interface ThirdProps {
  formik: FormikProps<{
    vality: string;
    cardNumber: string;
    cardName: string;
    cvc: string;
  }>;
}

const Third: FC<ThirdProps> = ({ formik }) => (
  <>
    <Input
      placeholder="Número do cartão"
      name="cardNumber"
      value={formik.values.cardNumber}
      onChange={e => {
        maskCardNumber(e.target.value, newValue => {
          formik.setFieldValue('cardNumber', newValue);
        });
      }}
      success={!formik.errors?.cardNumber && !!formik.values.cardNumber}
      error={!!formik.errors?.cardNumber && formik.touched.cardNumber}
      onBlur={formik.handleBlur}
    />
    <Input
      placeholder="Nome no cartão"
      name="cardName"
      value={formik.values.cardName}
      onChange={formik.handleChange}
      success={!formik.errors?.cardName && !!formik.values.cardName}
      error={!!formik.errors?.cardName && formik.touched.cardName}
      onBlur={formik.handleBlur}
    />
    <div className="row">
      <Input
        className="large"
        placeholder="Validade"
        name="vality"
        value={formik.values.vality}
        onChange={e => {
          maskDate(e.target.value, newValue => {
            formik.setFieldValue('vality', newValue);
          });
        }}
        success={!formik.errors?.vality && !!formik.values.vality}
        error={!!formik.errors?.vality && formik.touched.vality}
        onBlur={formik.handleBlur}
      />
      <Input
        placeholder="CVC"
        name="cvc"
        value={formik.values.cvc}
        onChange={e => {
          const valueNumber = e.target.value.match(/\d+/g)?.join('') || '';
          if (valueNumber?.length > 3) {
            return;
          }
          formik.setFieldValue('cvc', valueNumber);
        }}
        success={!formik.errors?.cvc && !!formik.values.cvc}
        error={!!formik.errors?.cvc && formik.touched.cvc}
        onBlur={formik.handleBlur}
      />
    </div>
  </>
);

export default Third;

import styled, { css } from 'styled-components';

export default styled.header<{ scroll: boolean; open: boolean }>`
  width: 100%;
  height: 7.25rem;

  background-color: #fff;
  > div {
    left: 0;
    top: 0;
    ${({ scroll }) =>
      scroll &&
      css`
        box-shadow: 0px 30px 60px -5px #e4f3ff;
      `};
    width: 100%;
    left: 50%;

    transform: translateX(-50%);
    position: fixed;
    z-index: 3;
    height: 7.25rem;
    background-color: #fff;
    padding: 0 5rem;

    max-width: 86rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0px 0px 1.5rem 1.5rem;
    transition: box-shadow 0.2s linear;
    background: #fff;

    .image {
      img {
        width: 10rem;
        object-fit: contain;
      }
      > button {
        cursor: pointer;
        border: none;
        background: none;
        display: none;

        &.close {
          img {
            width: 1.5rem;
          }
        }

        &.open {
          img {
            width: 2.5rem;
          }
        }
      }
    }

    nav {
      display: flex;
      ul {
        display: flex;
        list-style: none;
        gap: 3.75rem;
        align-items: center;
        li {
          a {
            text-decoration: none;
            position: relative;
            color: ${({ theme }) => theme.colors.primary};
            font-weight: 600;
            font-size: 1.31rem;

            ::after {
              content: '';
              position: absolute;
              bottom: -0.4rem;
              left: 0;
              transition: width 0.2s linear;
              width: 0;
              height: 3px;
              background-color: ${({ theme }) => theme.colors.secondary};
            }
            :hover {
              ::after {
                width: 100%;
              }
            }
          }

          .button {
            a:hover::after {
              width: 0;
            }
            min-width: 11rem;
          }
        }
      }
    }
  }

  @media (max-width: 700px) {
    > div {
      max-width: 100%;
      padding: 0;
      z-index: 3;

      .image {
        padding: 0 2em;
        display: flex;
        flex: 1;
        align-self: stretch;
        background: #fff;
        align-items: center;
        justify-content: space-between;

        border-radius: 0px 0px 1.5rem 1.5rem;

        > button {
          display: block;
          &.close {
            display: ${({ open }) => (open ? 'block' : 'none')};
          }

          &.open {
            display: ${({ open }) => (!open ? 'block' : 'none')};
          }
        }
      }

      nav {
        border-radius: 0px 0px 1.5rem 1.5rem;
        transform: translateY(-100%);
        top: 0;
        left: 0;
        width: 100%;
        background-color: #fff;
        position: absolute;
        top: 7.25rem;
        align-items: center;
        justify-content: center;
        padding: 1.25rem;
        z-index: -1;
        transition: transform 0.2s linear;

        ul {
          gap: 0;
          flex: 1;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          li {
            margin-bottom: 1.35rem;

            :last-child {
              border-top: 1px solid #c2deff;
              align-self: stretch;
              padding-top: 1.25rem;
              display: flex;
              align-items: center;
              justify-content: center;
              .button {
                max-width: 11.1rem;
              }
            }
          }
        }
      }

      ${({ open }) =>
        open &&
        css`
          border-radius: 0;

          nav {
            transform: translateY(0);
          }
          .image {
            border-radius: 0;
          }
        `}
    }
  }
`;

export const Modal = styled.button<{ show: boolean }>`
  position: fixed;
  z-index: 3;
  background: rgba(10, 33, 85, 0.25);
  backdrop-filter: blur(35px);
  width: 100%;
  height: 100%;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  outline: none;
  left: 0;
  top: 0;

  @media (min-width: 701px) {
    display: none;
  }
`;

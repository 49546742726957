import formikValues from '../formikValuesTypes';

import Input from 'components/Input';
import { FormikProps } from 'formik';
import { FC, useContext } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { ThemeContext } from 'styled-components';
import maskCep from 'utils/maskCep';

interface SecondProps {
  formik: FormikProps<{
    cep: string;
    uf: string;
    street: string;
    number: string;
    city: string;
    district: string;
    state: string;
  }>;
  states: Array<{
    id: number;
    sigla: string;
    nome: string;
  }>;
}

const First: FC<SecondProps> = ({ formik, states }) => {
  const { colors } = useContext(ThemeContext);
  return (
    <>
      <div className="row">
        <Input
          className="large"
          placeholder="CEP"
          name="cep"
          value={formik.values.cep}
          onChange={e => {
            maskCep(e.target.value, newValue => {
              formik.setFieldValue('cep', newValue);
            });
          }}
          success={!formik.errors?.cep && !!formik.values.cep}
          error={!!formik.errors?.cep && formik.touched.cep}
          onBlur={formik.handleBlur}
        />
        <div className="select">
          <div className="mask">
            <p>{formik.values.uf}</p>
            <MdKeyboardArrowDown size={32} color={colors.primary} />
          </div>
          <select onChange={e => formik.setFieldValue('state', e.target.value)}>
            {states.map((state, index) => (
              <option value={state.nome} key={index}>
                {state.nome}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row">
        <Input
          className="large"
          placeholder="Rua"
          name="street"
          value={formik.values.street}
          onChange={formik.handleChange}
          success={!formik.errors?.street && !!formik.values.street}
          error={!!formik.errors?.street && formik.touched.street}
          onBlur={formik.handleBlur}
        />
        <Input
          placeholder="N°"
          name="number"
          value={formik.values.number}
          onChange={formik.handleChange}
          success={!formik.errors?.number && !!formik.values.number}
          error={!!formik.errors?.number && formik.touched.number}
          onBlur={formik.handleBlur}
        />
      </div>
      <div className="row">
        <Input
          placeholder="Cidade"
          name="city"
          value={formik.values.city}
          onChange={formik.handleChange}
          success={!formik.errors?.city && !!formik.values.city}
          error={!!formik.errors?.city && formik.touched.city}
          onBlur={formik.handleBlur}
        />
        <Input
          placeholder="Bairro"
          name="district"
          value={formik.values.district}
          onChange={formik.handleChange}
          success={!formik.errors?.district && !!formik.values.district}
          error={!!formik.errors?.district && formik.touched.district}
          onBlur={formik.handleBlur}
        />
      </div>
    </>
  );
};

export default First;

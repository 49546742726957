import styled from 'styled-components';

export default styled.div`
  min-height: 28.12rem;
  width: 19.5rem;
  height: 28.12rem;
  border-radius: 1.5rem;
  box-shadow: 0px 50px 70px -5px #e4f3ff;
  overflow: hidden;

  .image {
    height: 50%;
    width: 100%;
    background: #e4f3ff;

    .slider-list,
    .slider-slide {
      height: 100% !important;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .slider {
    height: 100% !important;
  }

  .content {
    padding: 1.4rem;
    display: flex;
    flex-direction: column;
    h4 {
      font-size: 1.87rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.primary};
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      display: -webkit-box;
    }
    p {
      color: #949cb5;
      font-size: 1.31rem;
      margin-top: 0.62rem;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      display: -webkit-box;
    }
  }

  @media (max-width: 500px) {
    width: 100%;

    max-width: 19.5rem;
    height: 23rem;
    min-height: 23rem;

    .image {
      height: 45%;
    }

    .content {
      padding: 1rem;
    }
  }
  @media (max-width: 300px) {
    height: 28rem;
    min-height: 28rem;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 0.62rem;
  margin-top: 2.25rem;

  > div {
    flex: 2;
  }

  > button {
    flex: 1;
    border: 1.5px solid #c2deff;
    box-sizing: border-box;
    border-radius: 0.5rem;
    color: ${({ theme }) => theme.colors.primary};
    background: none;
    font-weight: 600;
    font-size: 1.31rem;
    cursor: pointer;
  }
  @media (max-width: 500px) {
    margin-top: 1rem;
  }

  @media (max-width: 300px) {
    flex-direction: column;

    > button {
      height: 3rem;
      flex: auto;
    }
  }
`;

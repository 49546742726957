function maskCpf(value: string, callback: (newValue: string) => void) {
  let newValue = value.match(/\d+/g)?.join('') || '';
  const { length } = newValue;
  if (length > 14) {
    return;
  }
  newValue = newValue.replace(/\D/g, '');
  newValue = newValue.replace(/^(\d{3})(\d+)/g, '$1.$2');
  newValue = newValue.replace(/^(\d{3})\.(\d{3})(\d)/g, '$1.$2.$3');
  newValue = newValue.replace(/^(\d{3})\.(\d{3})\.(\d{3})(\d)/g, '$1.$2.$3-$4');
  newValue = newValue.replace(
    /^(\d{2})(\d{1})\.(\d{2})(\d{1})\.(\d{2})(\d{1})-(\d{2})(\d)/g,
    '$1.$2$3.$4$5/$6$7$8'
  );
  newValue = newValue.replace(
    /^(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})(\d+)/g,
    '$1.$2.$3/$4-$5'
  );
  callback(newValue);

  // 61.567.578/0013-29
}

export default maskCpf;

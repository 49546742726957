/* eslint-disable react-hooks/exhaustive-deps */
import Container, { TextsTyping } from './styles';

import city from 'assets/img/city.svg';
import mouse from 'assets/img/mouse.svg';
import plant from 'assets/img/plant.svg';
import woman from 'assets/img/womanInPhone.svg';

import { useEffect, useState } from 'react';

const MainHeader = () => {
  const [texts, setTexts] = useState(['medicina', 'Ordontologia']);

  const [currentText, setCurrentText] = useState({
    currentText: '',
    index: 0,
    finished: false,
  });

  useEffect(() => {
    function addText(
      oddCurrentText: string,
      timestamp: number,
      index: number,
      oldTimestamb?: number
    ) {
      if (oddCurrentText.length >= texts[index].length) {
        let newIndex = index + 1;
        if (newIndex > texts.length - 1) {
          newIndex = 0;
        }

        setTimeout(() => {
          setCurrentText(props => ({
            ...props,
            finished: true,
          }));
        }, 500);
        setTimeout(() => {
          setCurrentText(props => ({
            ...props,
            currentText: '',
            index: newIndex,
            finished: false,
          }));
          requestAnimationFrame(newTimeStamp =>
            addText('', newTimeStamp, newIndex)
          );
        }, 1000);
        return;
      }
      if (oldTimestamb === undefined) {
        oldTimestamb = timestamp;
      }
      const progress = timestamp - oldTimestamb;

      if (progress >= 150) {
        const newCurrentText =
          oddCurrentText + texts[index][oddCurrentText.length];
        setCurrentText(props => ({
          ...props,
          currentText: newCurrentText,
        }));

        requestAnimationFrame(newTimeStamp =>
          addText(newCurrentText, newTimeStamp, index, timestamp)
        );
        return;
      }
      requestAnimationFrame(newTimeStamp =>
        addText(oddCurrentText, newTimeStamp, index, oldTimestamb)
      );
    }
    requestAnimationFrame(newTimeStamp =>
      addText(currentText.currentText, newTimeStamp, currentText.index)
    );
  }, []);
  return (
    <Container>
      <div className="content">
        <img className="plant" src={plant} alt="planta" />
        <span className="mouse">
          <img src={mouse} alt="mouse" />
        </span>
        <div>
          <h1>
            Um site moderno, limpo, comunicativo e modular, com as soluções que
            você precisa para colocar sua
            <strong>{' clínica '}</strong> de
          </h1>
          <TextsTyping finished={currentText.finished}>
            {currentText.currentText}
          </TextsTyping>
        </div>
        <img className="city" src={city} alt="cidade" />
        <img src={woman} alt="mulher sentada" />
        <span />
      </div>
    </Container>
  );
};

export default MainHeader;

import styled from 'styled-components';

export default styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5rem;
  h1 {
    padding-top: 7.5rem;
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 1.87rem;
    font-weight: 300;
    letter-spacing: 0.3em;
    text-transform: uppercase;
  }
  h2 {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600;
    font-size: 5rem;
    margin-bottom: 6.25rem;
    text-align: center;
  }

  @media (max-width: 1400px) {
    padding: 0 10rem;
  }
  @media (max-width: 1200px) {
    padding: 0 8rem;
  }

  @media (max-width: 900px) {
    h2 {
      margin-bottom: 3rem;
      font-size: 3rem;
    }
  }
  @media (max-width: 700px) {
    padding: 0 2em;
    h2 {
      font-size: 2.8rem;
    }
  }
`;

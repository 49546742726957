import Container from './styles';

import { MODULES } from 'services/graphql/queries';

import cardsMock from 'cardsMock.json';

import { useQuery } from '@apollo/client';
import Button from 'components/Button';
import Card from 'components/Card';
import CardDescription from 'components/CardDescription';
import Modal from 'container/Modal';
import Carrousel from 'nuka-carousel';
import { FC, useContext, useEffect, useState } from 'react';
import { IoSearch } from 'react-icons/io5';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import Context from 'services/Context';
import { ModuleRequest } from 'types/modules';

interface card {
  id: number;
  title: string;
  description: string;
  images: Array<{
    url: string;
  }>;
  price: number | null;
  active: boolean;
}
interface ModulesProps {
  openCart: () => void;
}

interface currentCardProps extends ModuleRequest {
  showModal: boolean;
}
interface ModulesQuery {
  modules: ModuleRequest[];
}
const Modules: FC<ModulesProps> = ({ openCart }) => {
  const { addProduct } = useContext(Context);
  const [cards, setCards] = useState<Array<ModuleRequest>>([]);
  const [cardsFilter, setCardsFilter] = useState<Array<Array<ModuleRequest>>>(
    []
  );
  const [currentCard, setCurrentCard] = useState<currentCardProps>({
    showModal: false,
    id: '-1',
    title: 'Titulo',
    description: 'paragrafo',
    images: [],
    price: 0,
    disabled: false,
    version: 0,
  });
  const [pages, setPages] = useState(0);
  const [slides, setSlides] = useState(3);
  const [filter, setFilter] = useState('');
  const { data } = useQuery<ModulesQuery>(MODULES);

  // useEffect(() => {
  //   const cardsMockSort = cardsMock.sort(cardMock => {
  //     if (!cardMock.active) {
  //       return 1;
  //     }
  //     return -1;
  //   });
  //   setCards(cardsMockSort);
  // }, []);

  useEffect(() => {
    if (!data) {
      return;
    }
    const newCards = data.modules.map(module => {
      // const newPriceFormat = `${module.price
      //   .toFixed(2)
      //   .toString()
      //   .replace('.', ',')}`;
      const newImages = module.images.map(image => ({
        ...image,
        source: `${process.env.REACT_APP_API_URL_IMAGE}/${image.source}`,
      }));
      return { ...module, images: newImages };
    });
    newCards.sort(cardMock => {
      if (!cardMock.disabled) {
        return 1;
      }
      return -1;
    });

    setCards(newCards);
  }, [data]);

  useEffect(() => {
    let currentColumn = 0;
    const newCards: Array<Array<ModuleRequest>> = [];
    cards.forEach((cardMock, index) => {
      if (newCards[currentColumn] === undefined) {
        newCards[currentColumn] = [];
      }
      if (
        !cardMock.title.toLowerCase().includes(filter.toString().toLowerCase())
      ) {
        return;
      }

      newCards[currentColumn].push(cardMock);

      if (currentColumn === slides - 1 && newCards[currentColumn].length < 2) {
        currentColumn = 0;
        return;
      }
      if (currentColumn > slides - 1 && newCards[currentColumn].length < 2) {
        return;
      }
      currentColumn++;
    });
    setCardsFilter(newCards);
  }, [cards, slides, filter]);

  useEffect(() => {
    const width = window.innerWidth;
    let slidesToShow = 3;
    if (width <= 1000) {
      slidesToShow = 2;
    }
    if (width <= 700) {
      slidesToShow = 1;
    }
    setSlides(slidesToShow);
  }, []);

  useEffect(() => {
    let newPages = cardsFilter.length - slides + 1;
    if (newPages <= 0) {
      newPages = 1;
    }

    setPages(newPages);
  }, [cardsFilter, slides]);

  return (
    <>
      <Modal
        percent={81}
        show={currentCard?.showModal}
        closeToBackground={() => {
          setCurrentCard(props => ({
            ...props,
            showModal: false,
          }));
        }}
      >
        <CardDescription
          closeModal={() => {
            setCurrentCard(props => ({
              ...props,
              showModal: false,
            }));
          }}
          price={currentCard.price}
          version={currentCard.version}
          images={currentCard.images}
          id={currentCard.id}
          disabled={currentCard.disabled}
          addModule={() => {
            addProduct(currentCard);
          }}
          description={currentCard.description}
          title={currentCard.title}
        />
      </Modal>
      <Container id="soluções">
        <h1>Contrate</h1>
        <h2>Conheça nossos módulos</h2>
        <div className="searchInput">
          <IoSearch size={32} color="#C2DEFF" />
          <input
            placeholder="Busque por um módulo"
            value={filter}
            onChange={e => setFilter(e.target.value)}
          />
        </div>
        <div className="content">
          <Carrousel
            onResize={() => {
              const width = window.innerWidth;
              let slidesToShow = 3;
              if (width <= 1000) {
                slidesToShow = 2;
              }
              if (width <= 700) {
                slidesToShow = 1;
              }
              setSlides(slidesToShow);
            }}
            dragging={false}
            className="carrousel"
            slidesToShow={slides}
            defaultControlsConfig={{
              nextButtonStyle: { display: 'none' },
              pagingDotsStyle: { display: 'none' },
              prevButtonStyle: { display: 'none' },
            }}
            renderBottomRightControls={e => {
              const arrayString: Array<string> = [];
              for (let i = 1; i <= pages; i++) {
                arrayString.push('');
              }
              return (
                <div className="buttons">
                  <button type="button" onClick={() => e.previousSlide()}>
                    <MdKeyboardArrowLeft size={40} color="#C2DEFF" />
                  </button>
                  <div className="dots_name">
                    {arrayString.map((value, index) => (
                      <button
                        key={index}
                        type="button"
                        className={e.currentSlide === index ? 'active' : ''}
                        onClick={() => e.goToSlide(index)}
                      />
                    ))}
                  </div>
                  <button type="button" onClick={() => e.nextSlide()}>
                    <MdKeyboardArrowRight size={40} color="#C2DEFF" />
                  </button>
                </div>
              );
            }}
          >
            {cardsFilter.map((cardColumn, index) => (
              <div className="cards" key={index}>
                {cardColumn.map(cardMap => (
                  <Card
                    key={cardMap.id}
                    card={cardMap}
                    addModule={addProduct}
                    openCard={() => {
                      setCurrentCard(() => ({
                        ...cardMap,
                        showModal: true,
                      }));
                    }}
                  />
                ))}
              </div>
            ))}
          </Carrousel>
        </div>
        <div>
          <Button type="button" onClick={openCart}>
            Quero começar meu site agora!
          </Button>
        </div>
      </Container>
    </>
  );
};

export default Modules;

import Container, { Form, Alert, TexArea } from './styles';

import Button from 'components/Button';
import Input from 'components/Input';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { FaWhatsapp, FaPhone } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { ThemeContext } from 'styled-components';
import contactUsSchema from 'validations/contactUsSchema';

const initialValues = {
  name: '',
  email: '',
  message: '',
  subject: '',
};

const ContactUs = () => {
  const { colors } = useContext(ThemeContext);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      console.log(values);
      setTimeout(() => {
        setLoading(false);
        setSuccess(true);
        resetForm();
      }, 1000);
    },
    validationSchema: contactUsSchema,
  });
  useEffect(() => {
    if (Object.keys(formik.errors).length > 0) {
      setSuccess(false);
    }
  }, [formik.errors]);
  return (
    <Container id="contato">
      <h1>Fale conosco</h1>
      <h2>Dúvidas ou sugestões?</h2>
      <div className="content">
        <div>
          <p>
            <strong>Lorem ipsum</strong> dolor sit amet, consectetur adipiscing
            elit. In posuere, tellus quis consectetur fringilla, felis neque
            luctus risus, a efficitur justo tortor vel urna.
          </p>

          <a href="tel:55555555555">
            <FaWhatsapp color={colors.primary} size={25} />
            <p>(55) 55555-5555</p>
          </a>
          <a href="tel:55555555555">
            <FaPhone color={colors.primary} size={25} />
            <p>(55) 55555-5555</p>
          </a>
          <a href="mailto:dolorsitamet@lorem.com">
            <MdEmail color={colors.primary} size={25} />
            <p>dolorsitamet@lorem.com</p>
          </a>
        </div>
        <Form onSubmit={formik.handleSubmit}>
          <div className="inputContainer">
            <Input
              placeholder="Seu nome *"
              value={formik.values.name}
              onChange={formik.handleChange}
              success={!formik.errors?.name && !!formik.values.name}
              error={!!formik.errors?.name && formik.touched.name}
              name="name"
              autoComplete="name"
              onBlur={formik.handleBlur}
            />
            <Input
              placeholder="E-mail *"
              value={formik.values.email}
              onChange={formik.handleChange}
              success={!formik.errors?.email && !!formik.values.email}
              error={!!formik.errors?.email && formik.touched.email}
              name="email"
              type="email"
              autoComplete="email"
              onBlur={formik.handleBlur}
            />
          </div>
          <Input
            placeholder="Assunto *"
            value={formik.values.subject}
            onChange={formik.handleChange}
            success={!formik.errors?.subject && !!formik.values.subject}
            error={!!formik.errors?.subject && formik.touched.subject}
            name="subject"
            onBlur={formik.handleBlur}
          />
          <TexArea
            placeholder="Mensagem *"
            error={
              (!!formik.errors?.message && formik.touched?.message) || false
            }
            value={formik.values.message}
            onChange={formik.handleChange}
            name="message"
            onBlur={formik.handleBlur}
          />
          <div className="buttons">
            {Object.keys(formik.errors).length > 0 && (
              <Alert type="error">Preencha todos os campos corretamente.</Alert>
            )}
            {!success && Object.keys(formik.errors).length <= 0 && (
              <Alert type="success" />
            )}

            {success && Object.keys(formik.errors).length <= 0 && (
              <Alert type="success">
                Sua mensagem foi enviada com successo!
              </Alert>
            )}
            <div className="button">
              <Button disabled={loading}>Enviar mensagem</Button>
            </div>
          </div>
        </Form>
      </div>
    </Container>
  );
};

export default ContactUs;

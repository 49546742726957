import Container from './styles';

import React, { FC } from 'react';

interface ModalProps {
  children: React.ReactNode;
  show: boolean;
  closeToBackground?: () => void;
  percent?: number;
}

const Modal: FC<ModalProps> = ({
  children,
  closeToBackground,
  show,
  percent,
}) => (
  <Container percent={percent} show={show}>
    <button type="button" onClick={closeToBackground} />
    <div>{children}</div>
  </Container>
);

Modal.defaultProps = {
  closeToBackground: () => {},
  percent: 89,
};

export default Modal;

import * as Yup from 'yup';

export default Yup.object().shape({
  cep: Yup.string()
    .matches(/\d{5}-\d{3}/g)
    .required(),
  uf: Yup.string().required(),
  street: Yup.string().required(),
  number: Yup.string().required(),
  city: Yup.string().required(),
  district: Yup.string().required(),
});

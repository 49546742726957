export default {
  title: 'light',
  colors: {
    primary: '#0470F4',
    secondary: '#2BD67B',
    terciary: '#0A2155',
  },
  fontFamily: {
    primary: 'Titillium Web',
    secondary: 'Rubik',
  },
};

import { gql } from '@apollo/client';

export const MODULES = gql`
  query {
    modules {
      title
      id
      version
      description
      price
      disabled
      images {
        mimetype
        source
        id
      }
    }
  }
`;

import Header from './components/Header';
import GlobalStyles from './theme/styles';

import About from 'components/About';
import Cart from 'components/Cart';
import ContactUs from 'components/ContactUs';
import FinishedBuy from 'components/FinishedBuy';
import Footer from 'components/Footer';
import MainHeader from 'components/MainHeader';
import Modules from 'components/Modules';
import Modal from 'container/Modal';
import { useState } from 'react';
import { AppContextProvider } from 'services/Context';
import { ThemeProvider } from 'styled-components';
import light from 'theme/light';

function App() {
  const [showModalBuy, setShowModalBuy] = useState(false);

  return (
    <AppContextProvider>
      <ThemeProvider theme={light}>
        <div className="App">
          <Cart openCartModal={() => setShowModalBuy(true)} />

          <Modal percent={100} show={showModalBuy} closeToBackground={() => {}}>
            <FinishedBuy closeModal={() => setShowModalBuy(false)} />
          </Modal>
          <GlobalStyles />
          <Header />
          <main>
            <MainHeader />
            <About />
            <Modules openCart={() => setShowModalBuy(true)} />
            <ContactUs />
          </main>
          <Footer />
        </div>
      </ThemeProvider>
    </AppContextProvider>
  );
}

export default App;

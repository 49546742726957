import formikValues from '../formikValuesTypes';

import Input from 'components/Input';
import { FormikProps } from 'formik';
import { FC } from 'react';
import maskCpf from 'utils/maskCpf';

interface FirstProps {
  formik: FormikProps<{
    name: string;
    cpfOrCnpj: string;
    email: string;
  }>;
}

const First: FC<FirstProps> = ({ formik }) => {
  console.log(!formik.errors?.name);
  return (
    <>
      <Input
        placeholder="Nome completo"
        name="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        success={!formik.errors?.name && !!formik.values.name}
        error={!!formik.errors?.name && formik.touched.name}
        onBlur={formik.handleBlur}
      />
      <Input
        placeholder="CPF/CNPJ"
        name="cpfOrCnpj"
        value={formik.values.cpfOrCnpj}
        onChange={e => {
          maskCpf(e.target.value, newValue => {
            formik.setFieldValue('cpfOrCnpj', newValue);
          });
        }}
        success={!formik.errors?.cpfOrCnpj && !!formik.values.cpfOrCnpj}
        error={!!formik.errors?.cpfOrCnpj && formik.touched.cpfOrCnpj}
        onBlur={formik.handleBlur}
      />
      <Input
        placeholder="Email"
        name="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        success={!formik.errors?.email && !!formik.values.email}
        error={!!formik.errors?.email && formik.touched.email}
        onBlur={formik.handleBlur}
      />
    </>
  );
};

export default First;

import Container, {
  ContentContainer,
  ModulesContainer,
  Module,
  Modal,
  CartContainer,
} from './styles';

import cartIcon from 'assets/img/cartIcon.svg';
import Button from 'components/Button';
import { FC, useContext, useState } from 'react';
import { IoIosArrowDropdown, IoIosArrowDropup } from 'react-icons/io';
import { MdClose } from 'react-icons/md';
import Context from 'services/Context';

interface CartProps {
  openCartModal: () => void;
}

const Cart: FC<CartProps> = ({ openCartModal }) => {
  const [open, setOpen] = useState(false);
  const { cart, removeProduct } = useContext(Context);

  return (
    <>
      <Modal show={open} type="button" onClick={() => setOpen(false)} />
      <CartContainer
        showCart={cart.totalProducts > 3}
        type="button"
        onClick={() => setOpen(true)}
      >
        <span>{cart.totalProducts}</span>
        <img src="/img/cartIcon.svg" alt="carrinho" />
      </CartContainer>
      <Container showCart={cart.totalProducts > 3} open={open}>
        <button type="button" onClick={() => setOpen(!open)}>
          <p>Módulos adicionados</p>
          <div>
            {open ? (
              <IoIosArrowDropdown size={36} color="#C2DEFF" />
            ) : (
              <IoIosArrowDropup size={36} color="#C2DEFF" />
            )}
          </div>
        </button>
        <ContentContainer>
          <ModulesContainer>
            {cart.products.map(product => (
              <Module key={product.id}>
                <p>{product.title}</p>
                {product.price !== null && (
                  <div>
                    <p>{product.priceFormat}</p>
                    <button
                      type="button"
                      onClick={() => removeProduct(product.id)}
                    >
                      <MdClose size={24} color="#C2DEFF" />
                    </button>
                  </div>
                )}
              </Module>
            ))}
          </ModulesContainer>
          <div className="price">
            <p>
              R$ <strong>{cart.totalFormat}</strong>
            </p>
            <p>/mês</p>
          </div>
          <div>
            <Button type="button" onClick={openCartModal}>
              Quero começar meu site agora!
            </Button>
          </div>
          <a href="#contato" onClick={() => setOpen(false)}>
            Dúvidas? Fale conosco
          </a>
        </ContentContainer>
      </Container>
    </>
  );
};

export default Cart;

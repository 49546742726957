import styled, { css } from 'styled-components';

export default styled.div<{ error: boolean }>`
  border: 2px solid #c2deff;
  box-sizing: border-box;
  border-radius: 1.56rem;
  color: #949cb5;
  font-size: 1.31rem;
  width: 100%;
  flex: 1;
  display: flex;
  overflow: hidden;
  padding-right: 1rem;
  align-items: center;

  min-height: 3.12rem;
  input {
    outline: none;
    overflow: hidden;
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    padding-left: 1rem;
    font-size: 1.31rem;
    flex: 1;
    align-self: stretch;
    margin-right: 1rem;
    border: none;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.primary};
    ::placeholder {
      color: #949cb5;
      font-weight: 400;
    }
  }

  ${({ error }) =>
    error &&
    css`
      border-color: #e77878;

      input {
        color: #e77878;
      }
    `}
`;

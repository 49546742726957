import styled from 'styled-components';

export default styled.footer`
  width: 100%;
  height: 5.87rem;
  background: #e4f3ff;
  padding: 0 17rem;
  justify-content: space-between;
  align-items: center;
  display: flex;
  > div {
    border-top: 1px solid #c2deff;
    justify-content: space-between;
    align-items: center;
    display: flex;
    align-self: stretch;
    flex: 1;
    img {
      width: 6.5rem;
    }

    p {
      font-size: 1.3rem;
      font-family: 'Ubuntu', sans-serif;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  @media (max-width: 1400px) {
    padding: 0 10rem;
  }

  @media (max-width: 900px) {
    padding: 0 5rem;
  }

  @media (max-width: 500px) {
    height: auto;
    > div {
      flex-direction: column;
      align-items: center;
    }
  }

  @media (max-width: 400px) {
    padding: 0 2rem;
    > div {
      padding: 2rem 0;
    }
  }
`;

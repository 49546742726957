import Container, { ButtonsContainer } from './styles';

import Button from 'components/Button';
import { ImageSlide } from 'components/CardDescription/styles';
import Carrousel from 'nuka-carousel';
import { FC, useContext, useEffect, useState } from 'react';
import Context from 'services/Context';
import { ModuleRequest } from 'types/modules';

interface CardProps {
  card: ModuleRequest;
  addModule: (value: ModuleRequest) => void;
  openCard: (value: ModuleRequest) => void;
}

const Card: FC<CardProps> = ({ card, openCard, addModule }) => {
  const { cart } = useContext(Context);
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    const existProduct = cart.products.find(product => product.id === card.id);
    if (existProduct) {
      setSelected(true);
      return;
    }
    setSelected(false);
  }, [cart.products, card.id]);
  return (
    <Container>
      <div className="image">
        <Carrousel
          autoplay
          defaultControlsConfig={{
            nextButtonStyle: { display: 'none' },
            pagingDotsStyle: { display: 'none' },
            prevButtonStyle: { display: 'none' },
          }}
        >
          {card.images.map((image, index) => (
            <img src={image.source} alt={`${card.title}${index}`} key={index} />
          ))}
        </Carrousel>
      </div>
      <div className="content">
        <h4>{card.title}</h4>
        <p>{card.description}</p>
        <ButtonsContainer>
          <div>
            <Button
              onClick={() => addModule(card)}
              type="button"
              disabled={!card.disabled}
              selected={selected}
            >
              {card.disabled
                ? `${selected ? 'Adicionado' : 'Adicionar'}`
                : 'Em breve'}
            </Button>
          </div>
          <button type="button" onClick={() => openCard(card)}>
            Ver
          </button>
        </ButtonsContainer>
      </div>
    </Container>
  );
};

export default Card;

/* eslint-disable react-hooks/exhaustive-deps */
import Container, {
  Module,
  ModulesContainer,
  ProductsContainer,
  ContentContainer,
} from './styles';

import First from './First';
import Second from './Second';
import Third from './Third';

import axios from 'axios';
import Button from 'components/Button';
import { useFormik } from 'formik';
import { FC, useContext, useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import Context from 'services/Context';
import firstBuySchema from 'validations/firstSBuySchema';
import secondBuySchema from 'validations/secondBuySchema';
import thirdBuySchema from 'validations/thirdBuySchema';

const firstInitialValues = {
  name: '',
  cpfOrCnpj: '',
  email: '',
};

const secondInitialValues = {
  cep: '',
  uf: 'RO',
  street: '',
  number: '',
  city: '',
  district: '',
  state: '',
};

const thirdInitialValues = {
  vality: '',
  cardNumber: '',
  cardName: '',
  cvc: '',
};

type initialValuesString =
  | 'name'
  | 'email'
  | 'cpfOrCnpj'
  | 'cep'
  | 'uf'
  | 'street'
  | 'number'
  | 'city'
  | 'district'
  | 'cardNumber'
  | 'cardName'
  | 'vality'
  | 'cvc';

interface FinishedBuyProps {
  closeModal: () => void;
}

const FinishedBuy: FC<FinishedBuyProps> = ({ closeModal }) => {
  const { removeProduct, cart } = useContext(Context);
  const [modalClose, setModalClose] = useState(false);
  const [loading, setLoading] = useState(false);
  const firstFormik = useFormik({
    initialValues: firstInitialValues,
    onSubmit() {
      setPage({
        name: 'Endereço de cobrança',
        page: 2,
      });
    },
    validationSchema: firstBuySchema,
  });
  const secondFormik = useFormik({
    initialValues: secondInitialValues,
    onSubmit() {
      setPage({
        name: 'Dados do cartão',
        page: 3,
      });
    },
    validationSchema: secondBuySchema,
  });
  const thirdFormik = useFormik({
    initialValues: thirdInitialValues,
    onSubmit(values) {
      setLoading(true);
      console.log(values);
      setInterval(() => {
        setLoading(false);
      }, 1000);
    },
    validationSchema: thirdBuySchema,
  });
  const [states, setStates] = useState<
    Array<{
      id: number;
      sigla: string;
      nome: string;
    }>
  >([]);
  const [page, setPage] = useState({
    name: 'Dados pessoais',
    page: 1,
  });

  useEffect(() => {
    async function fetchData() {
      if (!secondFormik?.values?.cep) {
        return;
      }
      const { cep } = secondFormik.values;
      const cepNumbers = cep.match(/\d+/g)?.join('') || '';
      if (cepNumbers.length < 8) {
        return;
      }
      const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      if (data?.erro) {
        return;
      }
      secondFormik.setValues({
        ...secondFormik.values,
        district: data.bairro,
        city: data.localidade,
        uf: data.uf,
        street: data.logradouro,
      });
    }
    fetchData();
  }, [secondFormik.values.cep]);

  useEffect(() => {
    async function fetchStates() {
      try {
        const response = await axios.get(
          'https://servicodados.ibge.gov.br/api/v1/localidades/estados'
        );
        setStates(response.data);
        secondFormik.setFieldValue('state', response.data[0].nome);
        secondFormik.setFieldValue('uf', response.data[0].sigla);
      } catch {
        console.log('erro');
      }
    }
    fetchStates();
  }, []);

  useEffect(() => {
    console.log('oio');
    if (!secondFormik.values.state) {
      return;
    }
    const uf = states.find(state => state.nome === secondFormik.values.state);
    secondFormik.setFieldValue('uf', uf?.sigla);
  }, [secondFormik.values.state, states]);

  return (
    <Container>
      {modalClose && (
        <div className="modalClose">
          <span />
          <div>
            <h2>Tem certeza que deseja sair?</h2>
            <p>Você perderá todos os dados já preenchidos.</p>
            <div className="buttons">
              <div>
                <Button onClick={() => setModalClose(false)} type="button">
                  Cancelar
                </Button>
              </div>
              <button
                onClick={() => {
                  setPage({
                    name: 'Dados pessoais',
                    page: 1,
                  });
                  firstFormik.resetForm();
                  secondFormik.resetForm();
                  thirdFormik.resetForm();
                  setModalClose(false);
                  closeModal();
                }}
                type="button"
              >
                Sair
              </button>
            </div>
          </div>
        </div>
      )}
      <button
        onClick={() => {
          setModalClose(true);
        }}
        className="button_close"
        type="button"
      >
        <MdClose size={16} color="#C2DEFF" />
      </button>
      <ProductsContainer>
        <h2>Módulos adicionados</h2>
        <ModulesContainer>
          {cart.products.map(product => (
            <Module key={product.id}>
              <p>{product.title}</p>
              {product.price !== null && (
                <div>
                  <p>{product.priceFormat}</p>
                  <button
                    type="button"
                    onClick={() => removeProduct(product.id)}
                  >
                    <MdClose size={24} color="#C2DEFF" />
                  </button>
                </div>
              )}
            </Module>
          ))}
        </ModulesContainer>
        <div className="price">
          <p>
            R$ <strong>{cart.totalFormat}</strong>
          </p>
          <p>/mês</p>
        </div>
        <button
          type="button"
          onClick={() => {
            setModalClose(true);
          }}
        >
          Adicionar mais módulos
        </button>
      </ProductsContainer>
      <ContentContainer>
        <div>
          <span>{page.page}/3</span>
          <h1>Finalização de compra</h1>

          <h2>{page.name}</h2>

          <form
            onSubmit={e => {
              e.preventDefault();
              if (page.page === 3) {
                thirdFormik.handleSubmit();
                return;
              }
              if (page.page === 1) {
                firstFormik.handleSubmit();
                return;
              }

              secondFormik.handleSubmit();
            }}
            className="inputs"
          >
            <button type="submit" style={{ display: 'none' }} />
            {page.page === 1 && <First formik={firstFormik} />}
            {page.page === 2 && (
              <Second formik={secondFormik} states={states} />
            )}
            {page.page === 3 && <Third formik={thirdFormik} />}
          </form>
          <div className="buttons">
            <div>
              <Button
                className={`${
                  loading ||
                  ((!firstFormik.isValid || !firstFormik.values.name) &&
                    page.page === 1) ||
                  ((!thirdFormik.isValid || !thirdFormik.values.cardName) &&
                    page.page === 3) ||
                  ((!secondFormik.isValid || !secondFormik.values.cep) &&
                    page.page === 2)
                    ? 'gray'
                    : ''
                }`}
                type="button"
                onClick={() => {
                  if (page.page === 3) {
                    thirdFormik.handleSubmit();
                    return;
                  }
                  if (page.page === 1) {
                    firstFormik.handleSubmit();
                    return;
                  }

                  secondFormik.handleSubmit();
                }}
              >
                {page.page === 3 ? 'Concluir' : 'Avançar'}
              </Button>
            </div>
            <button
              type="button"
              onClick={() => {
                if (page.page === 1) {
                  return;
                }
                if (page.page === 3) {
                  setPage({
                    name: 'Endereço de cobrança',
                    page: 2,
                  });
                  return;
                }
                setPage({
                  name: 'Dados pessoais',
                  page: 1,
                });
              }}
            >
              Voltar
            </button>
          </div>
        </div>
      </ContentContainer>
    </Container>
  );
};

export default FinishedBuy;

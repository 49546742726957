import Container, { Image } from './styles';

import { useCallback, useEffect, useRef } from 'react';

const About = () => {
  const refImageOne = useRef<HTMLImageElement>(null);
  const refImageTwo = useRef<HTMLImageElement>(null);
  const refImageThird = useRef<HTMLImageElement>(null);
  const refImageFour = useRef<HTMLImageElement>(null);
  const refImageFive = useRef<HTMLImageElement>(null);
  const refImageSix = useRef<HTMLImageElement>(null);
  const refArrowBlue = useRef<HTMLImageElement>(null);
  const refArrowGreen = useRef<HTMLImageElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const puzzeLeft = useCallback(
    (offset: number, width: number, percent: number) => {
      const distanceMiss = offset - width;
      if (distanceMiss >= offset) {
        return;
      }
      return distanceMiss + width * percent;
    },
    []
  );

  useEffect(() => {
    window.addEventListener('scroll', (e: any) => {
      if (!containerRef.current) {
        return;
      }
      let percent =
        e?.target?.scrollingElement?.scrollTop /
        (containerRef.current.offsetTop * 1.1);
      if (percent > 1) {
        percent = 1;
      }
      if (
        !refImageOne ||
        !refImageTwo ||
        !refImageTwo.current ||
        !refImageOne.current ||
        !refArrowBlue ||
        !refArrowBlue.current ||
        !refArrowGreen ||
        !refArrowGreen.current ||
        !refImageFive ||
        !refImageSix ||
        !refImageSix.current ||
        !refImageFive.current ||
        !refImageThird ||
        !refImageFour ||
        !refImageFour.current ||
        !refImageThird.current
      ) {
        return;
      }

      refArrowBlue.current.style.right = `${10 * percent}%`;
      refArrowGreen.current.style.left = `${10 * percent}%`;
      const value = puzzeLeft(
        refImageTwo?.current?.offsetLeft,
        refImageOne?.current?.clientWidth,
        0.4
      );
      if (!value) {
        return;
      }

      refImageOne.current.style.left = `${value * percent}px`;

      const valueTwo = puzzeLeft(
        refImageFour?.current?.offsetLeft,
        refImageThird?.current?.clientWidth,
        0.6
      );
      if (!valueTwo) {
        return;
      }
      refImageThird.current.style.left = `${valueTwo * percent}px`;

      const valueThird = puzzeLeft(
        refImageSix?.current?.offsetLeft,
        refImageFive?.current?.clientWidth,
        0.6
      );

      if (!valueThird) {
        return;
      }

      refImageFive.current.style.left = `${valueThird * percent}px`;
    });

    return window.removeEventListener('scroll', (e: any) => {
      if (!containerRef.current) {
        return;
      }
      let percent =
        e?.target?.scrollingElement?.scrollTop /
        (containerRef.current.offsetTop * 1.1);
      if (percent > 1) {
        percent = 1;
      }
      if (
        !refImageOne ||
        !refImageTwo ||
        !refImageTwo.current ||
        !refImageOne.current ||
        !refArrowBlue ||
        !refArrowBlue.current ||
        !refArrowGreen ||
        !refArrowGreen.current ||
        !refImageFive ||
        !refImageSix ||
        !refImageSix.current ||
        !refImageFive.current ||
        !refImageThird ||
        !refImageFour ||
        !refImageFour.current ||
        !refImageThird.current
      ) {
        return;
      }

      refArrowBlue.current.style.right = `${10 * percent}%`;
      refArrowGreen.current.style.left = `${10 * percent}%`;
      const value = puzzeLeft(
        refImageTwo?.current?.offsetLeft,
        refImageOne?.current?.clientWidth,
        0.4
      );
      if (!value) {
        return;
      }

      refImageOne.current.style.left = `${value * percent}px`;

      const valueTwo = puzzeLeft(
        refImageFour?.current?.offsetLeft,
        refImageThird?.current?.clientWidth,
        0.6
      );
      if (!valueTwo) {
        return;
      }
      refImageThird.current.style.left = `${valueTwo * percent}px`;

      const valueThird = puzzeLeft(
        refImageSix?.current?.offsetLeft,
        refImageFive?.current?.clientWidth,
        0.6
      );

      if (!valueThird) {
        return;
      }

      refImageFive.current.style.left = `${valueThird * percent}px`;
    });
  }, [puzzeLeft]);

  return (
    <Container ref={containerRef} id="sobre">
      <img src="/img/aboutBackground.svg" alt="background" />
      <h1>Solução</h1>
      <h2>Sobre a Clinsite</h2>
      <div className="content">
        <Image>
          <img src="/img/womanInPc.svg" alt="computador" />
          <img
            ref={refArrowBlue}
            src="/img/blueArrow.svg"
            alt="seta azul"
            className="blueArrow"
          />
          <img
            ref={refArrowGreen}
            src="/img/greenArrow.svg"
            alt="seta verde"
            className="greenArrow"
          />

          <img
            className="woman"
            alt="mulher no computador"
            src="/img/woman.svg"
          />
          <div className="puzzle">
            <img
              ref={refImageOne}
              src="/img/puzzleUnLockLeftLarger.svg"
              alt="puzzle"
            />
            <img
              ref={refImageTwo}
              src="/img/puzzleLockRight.svg"
              alt="puzzle"
            />
          </div>
          <div className="puzzle">
            <div className="puzzle2">
              <img
                ref={refImageThird}
                src="/img/puzzleLockLeftSmall.svg"
                alt="puzzle"
              />
              <img
                ref={refImageFour}
                src="/img/puzzleUnLockRight.svg"
                alt="puzzle"
              />
            </div>
            <div className="puzzle2">
              <img
                ref={refImageFive}
                src="/img/puzzleLockLeftSmall.svg"
                alt="puzzle"
              />
              <img
                ref={refImageSix}
                src="/img/puzzleUnLockRight.svg"
                alt="puzzle"
              />
            </div>
          </div>
        </Image>
        <div className="text">
          <p>
            <strong>{'Lorem ipsum '}</strong>
            dolor sit amet, consectetur adipiscing elit. In posuere, tellus quis
            consectetur fringilla, felis neque luctus risus, a efficitur justo
            tortor vel urna.
          </p>
          <p>
            Nunc enim elit, imperdiet non odio a, porta iaculis nibh. Proin
            interdum lorem vitae quam laoreet scelerisque. Cras pretium ac nisi
            in efficitur. Donec tristique condimentum dapibus. Sed cursus, dui
            et posuere volutpat, risus orci vulputate mi, nec vehicula purus mi
            vitae urna.
          </p>
        </div>
      </div>
    </Container>
  );
};

export default About;

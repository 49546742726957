import Container from './styles';

import logo from 'assets/img/logo.png';

const Footer = () => (
  <Container>
    <div>
      <p>&copy; 2019-2021 All Rights Reserved.</p>
      <img src={logo} alt="logo" />
    </div>
  </Container>
);

export default Footer;

import styled, { css } from 'styled-components';

export default styled.button<{ selected?: boolean }>`
  width: 100%;
  min-height: 3rem;
  background: linear-gradient(265.62deg, #2db5ff 0%, #0470f4 100%);
  border-radius: 0.5rem;
  border: none;
  padding: 0 1.87rem;
  color: #ffffff;
  font-size: 1.31rem;
  font-weight: bold;
  cursor: pointer;
  transition: box-shadow 0.2s linear;

  :hover {
    box-shadow: 0px 1rem 2rem -0.5rem rgba(4, 112, 244, 0.34);
  }

  :disabled {
    background: #c2deff;
  }

  ${({ selected }) =>
    selected &&
    css`
      background: linear-gradient(265.62deg, #2bd67b 0%, #00a267 100%);
    `}
`;

import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    font-family:${({ theme }) => theme.fontFamily.primary}, sans-serif;
    box-sizing:border-box;
    font-size:16px;
  }

  html, body, #root,.App{
    height: 100%;
    scroll-behavior:smooth;
  }

  p{
    font-family: ${({ theme }) => theme.fontFamily.secondary}, sans-serif;
  }

  hr {
    margin: 10px 0;
  }




  @media(max-width:1400px){
    html{
      font-size:80%
    }
  }

`;

import * as Yup from 'yup';

export default Yup.object().shape({
  cardNumber: Yup.string()
    .matches(/\d{4}\s\d{4}\s\d{4}\s\d{4}/g)
    .required(),
  cardName: Yup.string().required(),
  vality: Yup.string()
    .matches(/\d{2}\/\d{2}/g)
    .required(),
  cvc: Yup.string().min(3).max(3).required(),
});
